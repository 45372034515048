:root {
  // base
  --font-family: "Open Sans", sans-serif;

  // colors
  --light: #FAF6F1;
  --dark: #351E1C;
  --white: #FFFFFF;
  --orange: #FF583B;
  --gray-100: #e3e3e3;
  --gray-200: #7C7C7C;
  --brown: #664442;
  --violet-100: #d1c0e3;
  --violet-200: #6725AB;

  --gray-gradient: linear-gradient(180deg, #F8F8F8 0%, #F5F5F5 100%);
}
