.contacts {
  &__row {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    @media (max-width: 1199px) {
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__item {
    font-size: 15px;
    color: var(--dark);

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
