.section-main {
  position: relative;
  padding: 30px 0;
  background: var(--violet-200) url('../img/main-bg.svg') no-repeat center / cover;
  color: var(--white);
  text-align: center;

  h2, h3 {
    text-transform: uppercase;
  }

  &__subtitle {
    margin-bottom: 24px;
  }

  &__menu {
    font-size: 13px;
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--violet-100);

    h3 {
      color: var(--violet-200);
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 0;
    }

    &-tile {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 12px;
      background-color: var(--white);
      color: var(--dark);
      transition: all .2s ease-in-out;
      border: 1px solid var(--violet-100);

      img {
        margin-bottom: 8px;
      }

      p {
        margin-bottom: 0;
      }
    }

    a:hover {
      background-color: var(--violet-100);
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}
