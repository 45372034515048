.header {
  &-top {
    background-color: #f6f6f6;

    &__container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__menu {
      @media (max-width: 1199px) {
        order: 1;
      }
    }

    &__box {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__contacts {
      padding-left: 12px;
      border-left: 1px solid var(--gray-100);

      @media (max-width: 1199px) {
        padding-left: 0;
        border-left: none;
      }
    }

    &__account {
      .dropdown-toggle::after {
        content: none;
      }
    }

    .nav {
      &bar-nav {
        .nav-item:first-child {
          .nav-link {
            padding-left: 0;
          }
        }
        .nav-item:last-child {
          .nav-link {
            padding-right: 0;
          }
        }

        @media (max-width: 1199px) {
          margin: 20px 0;
        }
      }

      &bar-collapse {
        @media (max-width: 1199px) {
          position: absolute;
          top: 51px;
          background: white;
          z-index: 800;
          width: 100%;
          height: 100vh;
          overflow-y: auto;
          left: 0;
          padding: 15px;
        }
      }

      &-link {
        font-size: 13px;
      }
    }

    .dropdown {
      &-item {
        font-size: 14px;
      }

      &-toggle::after {
        width: 8px;
        height: 8px;
        border: none;
        background: url('../img/arrow.svg') no-repeat center / contain;
        vertical-align: middle;
      }
    }
  }

  &-bottom {
    margin: 8px 0 4px;

    @media (max-width: 767px) {
      margin: 8px 0;
    }

    &__burger {
      min-width: 138px;
      overflow: hidden;
      border: 1px solid var(--orange);
      width: auto;
      border-radius: 8px;
      padding: 0;
      display: inline-flex;
      align-items: center;

      img {
        padding: 12px 16px;
      }

      a {
        color: var(--white);
        font-size: 16px;
        background-color: var(--orange);
        padding: 12px 16px;
        transition: opacity 0.2s ease-in-out;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    &__logo {
      margin-left: 28.5px;

      @media (max-width: 1199px) {
        margin: 0;
      }
    }

    &__search {
      margin: 0 28.5px;
      width: 100%;
      max-width: 570px;

      @media (max-width: 991px) {
        max-width: 100%;
        margin: 0;
      }

      .btn-search {
        position: relative;
        padding: 24px;

        &::before {
          position: absolute;
          content: '';
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 20px;
          height: 20px;
          background: url('../img/search.svg') no-repeat center / contain;
        }
      }
    }

    &__data {
      margin-left: auto;
      display: flex;

      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: flex-start;
        color: var(--dark);
        font-size: 13px;

        &:not(:last-child) {
          margin-right: 16px;

          @media (max-width: 991px) {
            margin-right: 24px;
          }
        }

        img {
          margin-bottom: 4px;
        }

        @media (max-width: 991px) {
          align-items: center;
          max-width: 80px;
        }
      }
    }

    &__row {
      display: flex;
      align-items: center;
    }
  }
}
