.footer {
  color: var(--white);

  a {
    color: var(--white);
  }

  h2 {
    text-transform: uppercase;
  }

  &__col {
    padding: 0 18px;

    &:not(:last-child) {
      border-right: 1px solid #947c7a;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    @media (max-width: 991px) {
      padding: 0 !important;
      border-right: none !important;
    }
  }

  &-top {
    padding: 30px 0;
    background-color: var(--brown);

    @media (max-width: 991px) {
      padding: 30px 0;
    }

    p {
      margin-bottom: 10px;
      font-size: 15px;
    }

    h2 {
      font-size: 23px;
      margin-bottom: 28px;
    }

    &__row {
      display: flex;
      justify-content: space-between;

      @media (max-width: 991px) {
        flex-direction: column;
      }
    }

    &__logo {
      @media (max-width: 991px) {
        display: block;
        text-align: center;
      }
    }

    &__contacts {
      display: flex;
      flex-direction: column;
    }

    &__menu {
      display: flex;

      ul {
        max-width: 170px;

        li {
          white-space: nowrap;
        }
      }

      @media (max-width: 991px) {
        display: block;

        ul {
          max-width: 100%;
        }
      }
    }

    &__item {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: var(--white);
      margin-bottom: 10px;
      white-space: nowrap;

      img {
        margin-right: 10px;
      }

      &--small {
        font-size: 14px;
      }
    }
  }

  &-bottom {
    padding: 22px 0 30px;
    background-color: var(--dark);
    font-size: 11px;

    p {
      margin-bottom: 0;
    }

    a {
      color: var(--orange);
    }

    &__row {
      align-items: center;

      @media (max-width: 991px) {
        flex-direction: column;
      }
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    max-width: 278px;
    margin: 0 auto;

    @media (max-width: 991px) {
      margin: 20px 0;
    }

    &-icon {
      width: 44px;
      height: 44px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--white);
      margin: 0 20px;
      flex-shrink: 0;

      &:last-child {
        margin: 0;
      }
    }
  }
}
